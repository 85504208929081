// TODO passer en SCSS dans global.scss
@import 'variables';

#section-accueil-admin{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.menu-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.menu-item{
    width: fit-content;
    margin: 5px 10px;
    border: 3px solid white;
    border-radius: 20px;
    position: relative;
}

.menu-item:hover{
    border: 3px solid #3E5823;
    background-color: white;
}

.menu-item-has-child:hover{
    border-radius: 20px 20px 0 0;
}

.menu-item:hover i{
    color: #3E5823;
}

.menu-item:hover p{
    color: #3E5823;
}

.menu-item-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin: 10px 20px;
}

.menu-item-icon i{
    font-size: 20px;
}

.menu-item-title p{
    margin: 0 10px;
    font-size: 15px;
    text-align: center;
}

.submenu-group{
    max-height:0px;
    transform: scaleY(0);
    transform-origin: top;
    width: 100%;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    position: absolute;
    z-index: 4;
    background-color: white;
    border-radius: 0 0 20px 20px;
}

.menu-item:hover>.submenu-group{
    transform: scaleY(1);
    max-height: fit-content;
    outline: 3px solid #3E5823;
}

.submenu-item{
    width: 100%;
    height: 100%;
    border: none;
    padding: 10px 0;
    text-align: center;
}

.submenu-item a{
    width: 100%;
    height: 100%;
}

.submenu-item:last-of-type{
    border-radius: 0 0 10px 10px;
}

.submenu-item:hover{
    background-color: #3E5823;
}

.submenu-item:hover>a{
    color: white;
}

#user-action-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#user-profile-div{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#stop-imperso{
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    border: 3px solid white;
    margin: 0 50px 0 0;
    color: white;
	
}
#stop-imperso > a {
    padding: 10px;
    color: lightgray;
    
}

#stop-imperso > a:hover {
    color: white;
}


.custom-InfoDiv {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    padding-right: 30px;
}

.custom-InfoDiv > h1 {
    margin-bottom: 20px;
}

.custom-InfoDiv > p {
    margin: 0;
}

.custom-InfoDiv > button {
    background-color: white;
}

.userInfoIcon{
    font-size: 30px;
    color: #3E5823;
    margin: 0 20px;
    cursor: pointer;
}

#profilePage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
    padding-right: 60px;
}

#profilePage-form {
    background-color: white;
    border-radius: 0 30px 0 30px;
    padding: 50px 50px 50px 20px;
}

.accordion-toggle {
    cursor: pointer;
}

.accordion-toggle:hover {
    background-color: #d4eeb6;
}

#secNews {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media (max-width: $bp-tablet){
        flex-direction: column;
        width: 100%;
    }
}

#newFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.fileDate, .actuDate {
    background-color: #3E5823;
    color: white;
    padding: 10px 250px;
    font-size: 20px;
    font-weight: bolder;
    margin: 10px 0 0 0;
}

.fileTitle {
    text-decoration: underline;
}

.fileTitle:hover{
    text-decoration: none;
}

#newActu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.actuTitle {
    text-decoration: underline;
}

.actuTitle:hover{
    text-decoration: none;
}

.humiditeChk{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.humiditeChk:first-of-type{
    align-items: flex-start;
}

.mesure-humidite{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

/* input[required="required"]{
    border-color: red;
} */

input[disabled="disabled"]{
    border-color: #ccc;
}

.row.alignRow{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.row.justifyRow{
    display: flex;
    justify-content: space-around;
}

.tooltipForm{
    width: fit-content;
    position: relative;
}

.help-text{
    width: fit-content;
    position: absolute;
    visibility: hidden;
    bottom: 100%;
    background-color: #3E5823;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.help-text::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #3E5823 transparent transparent transparent;
}

.tooltipForm:hover .help-text{
    visibility: visible;
}

#pageDocs{
    display: flex;
    /* width: 100%; */
    /* flex-direction: column; */
}

.panel, .toColumn{
    display: flex;
    flex-direction: column;
    padding: 0;
}

#pageDocs .toRow{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin: 30px 0 20px 0;
}

.anneeDoc{
    margin: 0;
}

.groupMyDocs, .groupAllDocs {
    /* width: 100%; */
    background-color: #3E5823;
    color: white;
    font-size: 20px;
    margin: 20px 0 20px 0;
    border-radius: 10px;
    text-align: left;
}

.categoriesDocs {
    background-color: #3E5823 !important;
    color: white !important;
    font-size: 25px;
    border-radius: 10px;
}

.accordion{
    cursor: pointer;
}

.deploy{
    display: none;
}

.active.deploy{
    display: table-column;
}

#pageDocs .active.deploy{
    display: flex;
}

.groupMyDocs::after, .groupAllDocs::after {
    content: 'Voir les documents';
    font-size: 20px;
    float: right;
    margin-left: 5px;
}

.active.groupMyDocs::after, .active.groupAllDocs::after {
    content: "Fermer";
}

.fileGroupTitle{
    font-size: 15px;
    font-weight: bold;
    color: #3E5823;
    padding: 0;
}

.textLeft{
    text-align: left;
}

.textCenter{
    text-align: center;
}

.textRight{
    text-align: right;
}

#sortDiv{
    margin: 30px 0;
}

#sortDiv .sortBtn{
    background-color: #3E5823;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 50px;
    padding: 10px 30px;
    margin: 0 20px;
}

.section-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
    margin: 0;
    background-color: #3E5823;
    border-radius: 50px;
}

.section-title a{
    justify-self: right;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.section-title a:hover{
    color: #B8BE14;
}

#sechoirs .sechoirs-section {
    border: 20px solid #3E5823;
    background-color: #3E5823;
    margin: 50px 0;
    border-radius: 50px;
}

#sechoirs h2{
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.actu-sec {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.actu-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding: 5px;
    border: 5px solid #3E5823;
    height: 200px;
    border-radius: 10px 10px 10px 10px
}

.actu-div>img {
    max-height: 190px;
    max-width: 190px;
}

.actu-paginator>ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actu-paginator>ul>li {
    margin: 0 5px;
    font-size: 20px;
}

.actu-paginator>ul>li>a{
    color: #3E5823;
    font-weight: bold;
}

.actu-paginator .paginator-current{
    font-size: 25px;
    font-weight: bold;
}

#admin-docs .panel-body{
    padding: 2%;
    border: 3px solid #3E5823;
    border-radius: 0 0 10px 10px;
}

#admin-docs span.active{
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
}

#admin-docs .groupMyDocs::after, #admin-docs .groupAllDocs::after {
    content: 'Ouvrir';
    font-size: 20px;
    float: right;
    margin-left: 5px;
}

#admin-docs .active.groupMyDocs::after, #admin-docs .active.groupAllDocs::after {
    content: "Fermer";
}

label.required::after {
    content: " *";
    color: red;
}

.info-actu{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
}

.info-actu a{
    text-decoration: underline;
    color: blue;
}

@media (max-width: $bp-tablet) {
    .actu-div{
        flex-direction: column;
    }
}

.databaseSupp{
    padding: 5px 20px;
    border-radius: 50px;
    background-color: red;
    color: white;
    font-weight: bold;
}